<template>
  <v-app class="grey lighten-4">
    <NavBar />
    <v-main>
        <router-view name="MapLoader" />
        <router-view name="HomePage" />
        <router-view name="BelPage" />
        <router-view name="GroepsPage" />
        <router-view name="LoginPage" />
    </v-main>
  </v-app>
</template>

<script>
  
import NavBar from './components/NavBar.vue';
  export default {
    
    name: "app",
    components: {
      NavBar,
  },

};
</script>
