export default {
    namespaced: true,

    state: {
        token: null,
        user: "",
    },

    getters: {
        authenticated(state) {
            return state.token
        },
        CurrentUser(state) {
            return state.user
        },
    },

    mutations: {
        SET_TOKEN (state, token) {
            state.token = token
        },
        SET_USER(state, user) {
            state.user = user
        },

    },

    actions: {
        singOut({ commit }) {
            commit('SET_TOKEN', null)
        },

    }
}
