import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        components: {
            HomePage: () => import('@/views/HomePage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/BelPage',
        name: 'BelPage',
        components: {
            BelPage: () => import('@/views/BelPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/Vixit',
        name: 'Vixit',
        components: {
            HomePage: () => import('@/views/VixitPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            console.log(store.getters['auth/CurrentUser'])
            if (!(store.getters['auth/CurrentUser'] == "introcommissiealkmaar@gmail.com" || store.getters['auth/CurrentUser'] == "Vixit@omnivas.com" )) {
                console.log("Nee")
                return next({
                    name: 'login'
                })
            }
            console.log("Nee?")
            console.log("Nee?")
            next()
        }
    },
    {
        path: '/GroepsPage',
        name: 'GroepsPage',
        components: {
            GroepsPage: () => import('@/views/GroepsPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },

    {
        path: '/login',
        name: 'login',
        components: {
            LoginPage: () => import('@/views/LoginPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (store.getters['auth/authenticated']) {
                return next({
                    name: 'BelPage'
                })
            }

            next()
        }
    },

    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
       components: {
            loginpage: () => import('@/views/LoginPage.vue'),
        },
    },
]

const router = new VueRouter({
    routes
})

export default router
