<template>
    <nav>
        <v-app-bar flat app elevation="4" dense>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <img src="../assets/cropped-logo-txt-only-130-x-x.png" style="object-fit:contain;height:60px" />
        </v-app-bar>
        <v-navigation-drawer app v-model="drawer" temporary>
            <img src="../assets/cropped-logo-txt-only-130-x-x.png" style="object-fit:contain ;height:65px" class="center" />
            <v-container>
                <v-switch :value="darkMode"
                          @change="toggleDarkMode"
                          :label="` ${switchLabel} mode`">
                </v-switch>
            </v-container>
            <h1 class="center" style="margin-left: auto; margin-right: auto; width: 50%;">Plantmanager</h1>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item-group w>
                    <template v-if="authenticated">
                        <v-list-item v-for="(item, i) in authItems" :key="i" link @click="$router.push({ path: item.route })" @click.stop="drawer = !drawer">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-else>
                        <v-list-item v-for="(item, i) in loggedOutitems" :key="'A' + i" link @click="$router.push({ path: item.route })" @click.stop="drawer = !drawer">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-if="authUser == 'introcommissiealkmaar@gmail.com'">
                        <v-list-item v-for="(item, i) in IcItems" :key="'B' + i" @click="$router.push({ path: item.route })" @click.stop="drawer = !drawer">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-if="authUser == 'introcommissiealkmaar@gmail.com' || authUser == 'vixit@omnivas.com'">
                        <v-list-item v-for="(item, i) in IcVIxitItems" :key="'D' + i" @click="$router.push({ path: item.route })" @click.stop="drawer = !drawer">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-if="authenticated">
                        <v-list-item v-for="(item, i) in signOutItem" :key="'C' + i" @click.prevent="signOut" link @click.stop="drawer = !drawer">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {

        data: () => ({
            selectedItem: 0,

            authItems: [
                { text: 'DashBoard', icon: 'mdi-home', route: '/' },
                { text: 'Bellen', icon: 'mdi-cellphone', route: '/BelPage' },
            ],
            IcItems: [
                { text: 'Groepjes', icon: 'mdi-account-group', route: '/GroepsPage' },

            ],
            IcVIxitItems: [
                { text: 'Vixit', icon: 'mdi-hospital-box-outline', route: '/Vixit' },
            ],

            signOutItem: [
                { text: 'Sign out', icon: 'mdi-logout', route: '/login' },
            ],

            loggedOutitems: [

                { text: 'Login', icon: 'mdi-lock', route: '/login' },
            ],

            drawer: null,
            darkMode: false,
        }),
        methods: {
            toggleDarkMode: function () {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                this.darkMode = !this.darkMode;
            },
            ...mapActions({
                signOutAction: 'auth/singOut'
            }),
            signOut() {
                this.signOutAction().then(() => {
                    this.$router.push('/login')
                })
            }
        },
        computed: {

            ...mapGetters({
                authenticated: 'auth/authenticated',
            }),

            switchLabel: function () {
                return this.darkMode ? 'light' : 'dark';
            },
            authUser: function () {
                return this.$store.state.auth.user
            }
        },
        mounted: function () {
            this.$vuetify.theme.dark =true;
            this.darkMode = true;
        },
    }
</script>


