import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import VueGeolocation from 'vue-browser-geolocation'

Vue.use(VueGeolocation)
require('@/store/subscriber')
Vue.prototype.$store = store

axios.interceptors.request.use(
    (config) => {
        const token = Vue.prototype.$store.state.auth.token;
        console.warn(token)
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        if (error.response.status == 401) {
            Vue.prototype.$store.commit('auth/SET_TOKEN', null)

        }
        return Promise.reject(error);

    }
);
Vue.prototype.$axios = axios
Vue.prototype.$axios.defaults.baseURL ="https://development.veul.it:8003"
//Vue.prototype.$axios.defaults.baseURL = "https://localhost:8003"
Vue.config.productionTip = false


  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

