import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#2196F3',
                accent: '#2196F3',
                secondary: '#4FC3F7',
                success: '#4CAF50',
                info: '#1976D2',
                warning: '#FB8C00',
                error: '#C62828',
                highlightedBackground: '#333333',
            },
            light: {
                primary: '#1C397D',
                accent: '#2196F3',
                secondary: '#4FC3F7',
                success: '#4CAF50',
                info: '#1976D2',
                warning: '#FB8C00',
                error: '#C62828',
                highlightedBackground: '#EEEEEE',
            },
        },
      },
});
